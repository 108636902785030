<ap-dialog-title-template>{{data.title}}</ap-dialog-title-template>
<mat-dialog-content>
    <div class="ap-min-w-[500px]  ap-h-[500px] ap-max-h-[500px] ap-max-w-[750px]">
        <ngx-monaco-editor class="!ap-h-full !ap-w-full" [options]="jsonEditorOptionsMonaco"
            [formControl]="jsonFormControl"></ngx-monaco-editor>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="ap-flex ap-gap-2.5">
        <ap-button btnColor="basic" mat-dialog-close btnSize="default" i18n>
            Close
        </ap-button>
    </div>
</mat-dialog-actions>