import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@upbrains/ui/common';
import { catchError, of, tap } from 'rxjs';

@Component({
  templateUrl: './invite-redirect.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InviteRedirectionPageComponent implements OnInit {
  error: string | null = null;
  isLoading = true;

  constructor(
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    const token = this.route.snapshot.queryParams['invitation_token'];
    const email = this.route.snapshot.queryParams['email']; // Get the email from query params if available

    if (!token || !email) {
      this.error = 'Invalid invite link. Please check the URL and try again.';
      this.isLoading = false;
      return;
    }
    this.authService
      .verifyMemberByToken({ email, token })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.error = 'Verification failed. Please try again later.';
          this.isLoading = false;
          return of(null);
        }),
        tap((response) => {
          if (response) {
            if (response.registered) {
              this.router.navigate(['/sign-in'], {
                queryParams: { email, invitation_token: token },
              });
            } else {
              this.router.navigate(['/sign-up'], {
                queryParams: { email, invitation_token: token },
              });
            }
          } else {
            this.error = 'Verification failed. Please try again later.';
          }
          this.isLoading = false;
        })
      )
      .subscribe();
  }
}
