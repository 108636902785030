import { createSelector } from '@ngrx/store';
import { selectCommonState } from '../common.selector';
import { CommonStateModel } from '../common-state.model';
import { UpbrainsUserState } from './upbrains-user-state.model';

const selectUpbrainsUserState = createSelector(
  selectCommonState,
  (state: CommonStateModel): UpbrainsUserState => {
    return state.upbrainsUserState;
  }
);

const selectUpbrainsUserApiKey = createSelector(
  selectUpbrainsUserState,
  (state: UpbrainsUserState) => {
    return state.api_key;
  }
);

export const UpbrainsUserSelectors = {
  selectUpbrainsUserApiKey,
};
