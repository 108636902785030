<div class="ap-relative">
  <div
    class="ap-w-full ap-sticky ap-top-0 ap-left-0 ap-flex ap-flex-row ap-justify-between ap-items-center ap-h-[80px] ap-z-50 ap-shadow-[0px_1px_0px_#f0f0f0] ap-bg-[#f5f5f5]"
  >
    <div
      class="ap-absolute ap-w-full ap-h-full ap-left-0 ap-top-0 ap-flex ap-items-center ap-justify-center ap-p-3 ap-pointer-events-none"
    >
      <div class="ap-h-[30px]">
        <img
          [src]="logoSrc || 'assets/img/custom/logo/full-logo.svg'"
          class="ap-max-w-full ap-h-full"
          alt="Upbrains AI"
        />
      </div>
    </div>

    <div
      class="ap-flex-1 ap-flex ap-flex-row ap-justify-start ap-items-center ap-gap-2"
    >
      <ap-icon-button
        [width]="20"
        [height]="20"
        iconUrl="assets/img/custom/dashboard/home.svg"
        tooltipText="Home"
        i18n-tooltipText
        (buttonClicked)="redirectHome(false)"
        (auxclick)="redirectHome(true)"
      ></ap-icon-button>
      <div class="ap-flex ap-flex-row ap-gap-2">
        <div
          class="mat-mdc-tooltip-trigger ap-typography-body-1 ap-truncate ap-max-w-[150px] ap-cursor-pointer hover:ap-text-[#000000] ng-tns-c2280036445-12 ng-star-inserted"
          (click)="redirectToInbox(false)"
          (auxclick)="redirectToInbox(true)"
        >
          Forms
        </div>
        <div
          class="ap-typography-body-1 ap-text-placeholder ng-tns-c2280036445-12 ng-star-inserted"
        >
          /
        </div>
        <div
          class="mat-mdc-tooltip-trigger !ap-font-semibold ap-typography-body-1 viewed-text-container ng-star-inserted"
        >
          {{ title }}
        </div>
      </div>
    </div>
    <div
      class="ap-flex-1 ap-flex ap-flex-row ap-justify-end ap-items-center ap-gap-2"
    ></div>
  </div>

  <div
    class="ap-w-full ap-h-full ap-flex ap-flex-col ap-items-center ap-justify-start ap-overflow-auto"
  >

    <div
      class="ap-py-8 ap-my-10 ap-flex-1 ap-overflow-auto ap-rounded ap-shadow-step-container-ds ap-bg-white ap-w-4/5"
    >
      <div class="ap-h-full ap-flex ap-flex-col">
        <div
          class="ap-flex ap-justify-between ap-items-center ap-border-b ap-px-8 ap-pb-6"
        >
          <div
            class="ap-flex ap-items-center ap-gap-2 ap-font-normal ap-text-base"
          >
            {{ title }}
          </div>

          <div class="ap-flex ap-flex-row ap-justify-between ap-gap-2">
            <ng-container *ngIf="fileUrl">
              <ap-button
                actionButton
                (buttonClicked)="openFile()"
                btnSize="medium"
                btnColor="primary"
                i18n
                btnStyle="stroked"
                >File</ap-button
              >
            </ng-container>

            <ng-container *ngIf="text">
              <ap-button
                actionButton
                (buttonClicked)="openText()"
                btnSize="medium"
                btnColor="primary"
                i18n
                btnStyle="stroked"
                >Text</ap-button
              >
            </ng-container>
          </div>
        </div>

        <div
          class="ap-overflow-auto ap-overflow-x-hidden ap-mt-3 ap-mb-3 ap-px-5"
        >
          <div
            *ngIf="hasProperties(extractorInfoObj) && isDoc"
            class="ap-w-full ap-flex ap-flex-row ap-flex-wrap ap-my-5 ap-py-3 ap-px-5 ap-rounded ap-leading-8"
          >
            <div
              *ngFor="let key of getObjectKeys(extractorInfoObj)"
              class="ap-min-w-[286px] ap-flex-1 ap-flex ap-flex-row ap-items-center"
            >
              <div
                class="mat-mdc-tooltip-trigger !ap-font-semibold ap-typography-body-1 viewed-text-container ng-star-inserted ap-mr-2"
              >
                {{ key }}:
              </div>
              <div class="!ap-font-thin">{{ extractorInfoObj[key] }}</div>
            </div>
          </div>

          <div
            *ngIf="shortFieldsForm?.length && showFields"
            class="ap-w-full ap-grid ap-grid-cols-1 sm:ap-grid-cols-2 ap-gap-x-10 ap-gap-5 gap ap-my-5"
          >
            <div
              *ngFor="let field of shortFieldsForm"
              class="ap-flex ap-flex-col ap-gap-1 ap-items-start ap-mx-20"
            >
              <div class="ap-flex ap-items-center ap-justify-center">
                <mat-icon
                  class="ap-me-2"
                  *ngIf="field.type === 'Address'"
                  fontIcon="pin_drop"
                ></mat-icon>
                <mat-icon
                  class="ap-me-2"
                  *ngIf="field.type === 'Date'"
                  fontIcon="date_range"
                ></mat-icon>
                <mat-icon
                  class="ap-me-2"
                  *ngIf="field.type === 'String'"
                  fontIcon="text_rotation_none"
                ></mat-icon>
                <label
                  class="ap-font-inter ap-block ap-text-sm ap-font-medium ap-text-gray-900 ap-dark:text-white"
                  >{{ field.key }}</label
                >
              </div>

              <mat-form-field
                class="ap-w-full ap-font-inter ap-max-w-[400px]"
                appearance="fill"
                subscriptSizing="dynamic"
              >
                <input
                  type="text"
                  matInput
                  [(ngModel)]="field.content"
                  class="ap-pointer-events-none ap-outline-none"
                />
              </mat-form-field>
            </div>
          </div>

          <div
            *ngIf="longFieldsForm.length && showFields"
            class="ap-w-full ap-flex ap-flex-wrap ap-gap-3 ap-mb-5"
          >
            <div
              *ngFor="let field of longFieldsForm"
              class="ap-flex-1 ap-flex ap-flex-row ap-items-center ap-gap-2"
            >
              <div class="ap-flex ap-items-center ap-justify-center">
                <mat-icon
                  class="ap-me-2"
                  *ngIf="field.type === 'Address'"
                  fontIcon="pin_drop"
                ></mat-icon>
                <mat-icon
                  class="ap-me-2"
                  *ngIf="field.type === 'Date'"
                  fontIcon="date_range"
                ></mat-icon>
                <mat-icon
                  class="ap-me-2"
                  *ngIf="field.type === 'String'"
                  fontIcon="text_rotation_none"
                ></mat-icon>
                <label
                  class="ap-font-inter ap-block ap-text-sm ap-font-medium ap-text-gray-900 ap-dark:text-white"
                  >{{ field.key }}</label
                >
              </div>

              <mat-form-field
                class="ap-w-full ap-max-w-[250px]"
                appearance="fill"
                subscriptSizing="dynamic"
              >
                <textarea
                  matInput
                  [(ngModel)]="field.content"
                  class="ap-pointer-events-none ap-outline-none"
                  rows="4"
                ></textarea>
              </mat-form-field>
            </div>
          </div>

          <ng-container *ngIf="showTable">
            <div>
              <app-table [columns]="column" [data]="data"></app-table>
            </div>
            <div class="ap-w-full ap-flex ap-justify-end ap-pt-4 ap-border-t">
              <ap-button
                btnSize="medium"
                btnColor="primary"
                btnStyle="stroked"
                (buttonClicked)="addNewRow()"
              >
                <span i18n>+ New Row</span>
              </ap-button>
            </div>
          </ng-container>
        </div>

        <div
          class="ap-flex ap-flex-row ap-justify-end ap-items-center ap-gap-2 ap-mt-8 ap-px-8"
        >
          <ap-button
            actionButton
            (buttonClicked)="handleSaving()"
            btnSize="large"
            btnColor="primary"
            i18n
          >
            <div class="ap-flex ap-items-center loading-template">
              <svg-icon
                *ngIf="isSaving"
                src="assets/img/custom/loading.svg"
                class="loading-spin-animation white-loader"
                [applyClass]="true"
              >
              </svg-icon>
              {{ isSaving ? 'Saving...' : 'Save' }}
            </div>
          </ap-button>
        </div>
      </div>
    </div>
  </div>
</div>
