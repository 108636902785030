import { Static, Type } from '@sinclair/typebox'
import { MemberRole } from './member'

export const PostMemberRequest = Type.Object({
    email: Type.String(),
    role: Type.Enum(MemberRole),
    invitedAt: Type.String(),
    invitedBy: Type.String(),
    submittedAt: Type.String(),
    projectId: Type.String(),
    userId: Type.String(),
    inviteSecret: Type.String(),
    registryDate: Type.String(),
})

export type PostMemberRequest = Static<typeof PostMemberRequest>

export const GetIdParams = Type.Object({
    id: Type.String(),
    projectId: Type.String(),
})

export type GetIdParams = Static<typeof GetIdParams>

export const ListProjectMemberRequestQuery = Type.Object({
    cursor: Type.Optional(Type.String({})),
    projectId: Type.String(),
    limit: Type.Optional(Type.Number({})),
})
export type ListProjectMemberRequestQuery = Static<
    typeof ListProjectMemberRequestQuery
>
