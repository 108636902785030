import { Static, Type } from '@sinclair/typebox'
import { BaseModelSchema } from '../common/base-model'
import { ApId } from '../common/id-generator'
import { User } from '../user'
export type MemberId = ApId

export enum MemberRole {
    ADMIN = 'ADMIN',
    MEMBER = 'MEMBER',
    PLATFORM_ADMIN = 'PLATFORM_ADMIN',
}

export const Member = Type.Object({
    ...BaseModelSchema,
    email: Type.String(),
    role: Type.Enum(MemberRole),
    invitedAt: Type.String(),
    invitedBy: Type.String(),
    submittedAt: Type.String(),
    platformId: Type.Union([Type.String(), Type.Null()]),
    projectId: Type.String(),
    userId: Type.String(),
    inviteSecret: Type.Optional(Type.String()),
    registryDate: Type.String(),
    user: Type.Optional(User),
})

export type Member = Static<typeof Member>

export const MemberColumns = Type.Object({
    email: Type.String(),
    name: Type.String(),
    role: Type.Enum(MemberRole),
    status: Type.String(),
    invitedAt: Type.String(),
    invitedBy: Type.String(),
    submittedAt: Type.String(),
    registryDate: Type.String(),
})

export type MemberColumns = Static<typeof MemberColumns>
