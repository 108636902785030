import { createAction, props } from '@ngrx/store';
import { UpbrainsUserState } from './upbrains-user-state.model';

export const saveUpbrainsUser = createAction(
  '[Upbrains User] Save UpBrains User',
  props<{ upbrainsUser: UpbrainsUserState }>()
);
export const removeUpbrainsUser = createAction(
  '[Upbrains User] Remove UpBrains User'
);
