<div class="ap-relative">
  <div
    class="ap-w-full ap-sticky ap-top-0 ap-left-0 ap-flex ap-flex-row ap-justify-between ap-items-center ap-h-[80px] ap-z-50 ap-shadow-[0px_1px_0px_#f0f0f0] ap-bg-[#f5f5f5]"
  >
    <div
      class="ap-absolute ap-w-full ap-h-full ap-left-0 ap-top-0 ap-flex ap-items-center ap-justify-center ap-p-3 ap-pointer-events-none"
    >
      <div class="ap-h-[30px]">
        <img
          [src]="logoSrc || 'assets/img/custom/logo/full-logo.svg'"
          class="ap-max-w-full ap-h-full"
          alt="Upbrains AI"
        />
      </div>
    </div>

    <div
      class="ap-flex-1 ap-flex ap-flex-row ap-justify-start ap-items-center ap-gap-2"
    >
      <ap-icon-button
        [width]="20"
        [height]="20"
        iconUrl="assets/img/custom/dashboard/home.svg"
        tooltipText="Home"
        i18n-tooltipText
        (buttonClicked)="redirectHome(false)"
        (auxclick)="redirectHome(true)"
      ></ap-icon-button>
      <div class="ap-flex ap-flex-row ap-gap-2">
        <div
          class="mat-mdc-tooltip-trigger ap-typography-body-1 ap-truncate ap-max-w-[150px] ap-cursor-pointer hover:ap-text-[#000000] ng-tns-c2280036445-12 ng-star-inserted"
          (click)="redirectToInbox(false)"
          (auxclick)="redirectToInbox(true)"
        >
          Forms
        </div>
        <div
          class="ap-typography-body-1 ap-text-placeholder ng-tns-c2280036445-12 ng-star-inserted"
        >
          /
        </div>
        <div
          class="mat-mdc-tooltip-trigger !ap-font-semibold ap-typography-body-1 viewed-text-container ng-star-inserted"
        >
          {{ title }}
        </div>
      </div>
    </div>
    <div
      class="ap-flex-1 ap-flex ap-flex-row ap-justify-end ap-items-center ap-gap-2"
    ></div>
  </div>

  <div
    class="ap-flex ap-justify-between ap-items-center ap-border-b ap-mx-4 ap-mt-4 ap-rounded ap-rounded-b-none ap-shadow-step-container-ds ap-bg-white ap-px-4 ap-py-4"
  >
    <div class="ap-flex ap-items-center ap-gap-2 ap-font-normal ap-text-base">
      {{ title }}
    </div>
  </div>
  <div
    class="ap-grid ap-gap-4 container-height ap-shadow-step-container-ds ap-bg-white ap-mx-4"
    [ngClass]="handlingAgGridClassMainContainer()"
  >
    <div
      resizable
      [percent]="secondContainer || thirdContainer ? 25 : 100"
      *ngIf="firstContainer"
      [ngClass]="handlingAgGridClassFieldsContainer()"
      class="!ap-overflow-y-auto ap-p-8 ap-border-r ap-bg-gray-100"
    >
      <div
        *ngIf="hasProperties(extractorInfoObj) && isDoc"
        class="ap-w-full ap-flex ap-flex-row ap-flex-wrap ap-my-5 ap-rounded ap-leading-8"
      >
        <div
          *ngFor="let key of getObjectKeys(extractorInfoObj)"
          class="ap-min-w-[286px] ap-flex-1 ap-flex ap-flex-row ap-items-center"
        >
          <div
            class="mat-mdc-tooltip-trigger !ap-font-semibold ap-typography-body-1 viewed-text-container ng-star-inserted ap-mr-2"
          >
            {{ key }}:
          </div>
          <div class="!ap-font-thin">{{ extractorInfoObj[key] }}</div>
        </div>
      </div>

      <div
        *ngIf="shortFieldsForm?.length && showFields"
        class="ap-w-full ap-flex ap-flex-wrap ap-gap-4"
      >
        <div
          *ngFor="let field of shortFieldsForm"
          class="ap-flex ap-flex-col ap-items-start ap-flex-1 min-w-[calc(50%-8px)]"
        >
          <div class="ap-grid ap-w-full">
            <label
              class="ap-font-inter ap-block ap-text-sm ap-font-medium ap-text-gray-900 ap-dark:text-white text-ellipsis"
              >{{ field.key }}</label
            >
          </div>

          <mat-form-field
            class="ap-w-full ap-font-inter"
            subscriptSizing="dynamic"
          >
            <mat-icon
              class="ap-me-2"
              *ngIf="field.type === 'Address'"
              fontIcon="pin_drop"
              matPrefix
            ></mat-icon>
            <mat-icon
              class="ap-me-2"
              *ngIf="field.type === 'Date'"
              fontIcon="date_range"
              matPrefix
            ></mat-icon>
            <mat-icon
              *ngIf="field.type === 'String'"
              fontIcon="text_rotation_none"
              matPrefix
            ></mat-icon>
            <input
              type="text"
              matInput
              [(ngModel)]="field.content"
              (ngModelChange)="onFieldChange(field.content, field.key)"
              (click)="
                highlightText(field.content, field.page_number, shortFieldsForm)
              "
              class="ap-outline-none ap-w-full"
            />
          </mat-form-field>
        </div>
      </div>

      <div
        *ngIf="longFieldsForm.length && showFields"
        class="ap-w-full ap-flex ap-flex-wrap ap-gap-4"
      >
        <div
          *ngFor="let field of longFieldsForm"
          class="ap-flex ap-flex-col ap-items-start ap-flex-1 min-w-[calc(50%-8px)]"
        >
          <div class="ap-grid ap-w-full">
            <label
              class="ap-font-inter ap-block ap-text-sm ap-font-medium ap-text-gray-900 ap-dark:text-white text-ellipsis"
              >{{ field.key }}</label
            >
          </div>

          <mat-form-field
            class="ap-w-full ap-font-inter"
            subscriptSizing="dynamic"
          >
            <mat-icon
              class="ap-me-2"
              *ngIf="field.type === 'Address'"
              fontIcon="pin_drop"
              matPrefix
            ></mat-icon>
            <mat-icon
              class="ap-me-2"
              *ngIf="field.type === 'Date'"
              fontIcon="date_range"
              matPrefix
            ></mat-icon>
            <mat-icon
              *ngIf="field.type === 'String'"
              fontIcon="text_rotation_none"
              matPrefix
            ></mat-icon>
            <textarea
              matInput
              [(ngModel)]="field.content"
              class="ap-outline-none ap-w-full"
              (click)="
                highlightText(field.content, field.page_number, longFieldsForm)
              "
              rows="4"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div
      resizable
      [percent]="firstContainer ? 75 : 100"
      class="ap-flex ap-flex-col"
      *ngIf="secondContainer || thirdContainer"
    >
      <div
        resizable
        [percent]="thirdContainer ? 30 : 100"
        *ngIf="secondContainer"
        [ngClass]="handlingAgGridClassTextContainer()"
        class="ap-relative !ap-overflow-auto"
      >
        <div
          class="ap-w-full ap-bg-white ap-flex ap-flex-row ap-justify-end ap-items-center ap-gap-1 ap-px-2 ap-py-2 ap-sticky ap-top-0 ap-z-50"
        >
          <ap-button
            *ngIf="hasJsonButton"
            actionButton
            (buttonClicked)="switchPdfTextMode('json')"
            btnSize="medium"
            btnColor="primary"
            i18n
            btnStyle="stroked"
            >Json</ap-button
          >
          <ap-button
            *ngIf="emailConfig"
            actionButton
            (buttonClicked)="switchPdfTextMode('email')"
            btnSize="medium"
            btnColor="primary"
            i18n
            btnStyle="stroked"
            >Email</ap-button
          >
          <ap-button
            *ngIf="text"
            actionButton
            (buttonClicked)="switchPdfTextMode('text')"
            btnSize="medium"
            btnColor="primary"
            i18n
            btnStyle="stroked"
            >Text</ap-button
          >
          <ap-button
            *ngIf="fileUrl"
            actionButton
            (buttonClicked)="switchPdfTextMode('file')"
            btnSize="medium"
            btnColor="primary"
            i18n
            btnStyle="stroked"
            >File</ap-button
          >
        </div>

        <ng-container *ngIf="currentViewMode === 'json'">
          <div class="ap-w-full ap-h-[calc(100%-52px)] ap-overflow-auto">
            <ngx-json-viewer
              [json]="staticFormsData?.formData"
            ></ngx-json-viewer>
          </div>
        </ng-container>

        <ng-container *ngIf="currentViewMode === 'text'">
          <div class="custom-container">
            <!-- Page Content -->
            <div class="custom-content">
              <pre
                *ngIf="formPages[currentPageIndex]"
                [innerHTML]="sanitizeHtml(formPages[currentPageIndex].text)"
                class="ap-px-2 ap-text-sm ap-font-normal"
                (contextmenu)="onRightClick($event)"
              ></pre>
            </div>

            <!-- Page Navigator -->
            <div class="custom-page-navigation">
              <button
                (click)="goToPreviousTextPage()"
                [disabled]="currentPageIndex === 0"
                [ngClass]="{
                  'ap-opacity-[0.2]': currentPageIndex === 0
                }"
              >
                <svg-icon
                  [svgStyle]="{ width: '20px', height: '20px' }"
                  class="ap-flex ap-justify-center ap-items-center"
                  src="assets/img/custom/paginator/back.svg"
                >
                </svg-icon>
              </button>

              <span class="ap-mx-4">
                {{ currentPageIndex + 1 }} of {{ formPages.length }}
              </span>

              <button
                (click)="goToNextTextPage()"
                [disabled]="currentPageIndex === formPages.length - 1"
                [ngClass]="{
                  'ap-opacity-[0.2]': currentPageIndex === formPages.length - 1
                }"
              >
                <svg-icon
                  [svgStyle]="{ width: '20px', height: '20px' }"
                  class="ap-flex ap-justify-center ap-items-center"
                  src="assets/img/custom/paginator/next.svg"
                >
                </svg-icon>
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="currentViewMode === 'file'">
          <object
            [data]="pdfUrl"
            type="application/pdf"
            width="100%"
            height="100%"
            *ngIf="pdfUrl"
          >
            <p>
              Your browser does not support PDFs. Please download the PDF to
              view it:
              <a href="https://www.example.com/sample.pdf">Download PDF</a>.
            </p>
          </object>
        </ng-container>
        <ng-container *ngIf="currentViewMode === 'email' && emailConfig">
          <div class="ap-overflow-auto ap-w-full custom-email-height">
            <ap-email-showcase
              [attachments]="attachments"
              [emailConfig]="emailConfig"
            ></ap-email-showcase>
          </div>
        </ng-container>
      </div>
      <div
        resizable
        [percent]="secondContainer ? 70 : 100"
        *ngIf="thirdContainer"
        [ngClass]="handlingAgGridClassTableContainer()"
        class="ap-overflow-hidden ap-border-t"
      >
        <ng-container *ngIf="showTable">
          <div
            class="ap-flex ap-flex-row ap-justify-between ap-items-center ap-bg-neutral-50 ap-z-50 ap-px-1 ap-py-2 ap-rounded-sm ap-sticky ap-top-0"
          >
            <p class="ap-text-sm">Extracted Items</p>
            <ap-button
              btnSize="medium"
              btnColor="primary"
              btnStyle="stroked"
              (buttonClicked)="addNewRow()"
            >
              <span i18n>+ New Row</span>
            </ap-button>
          </div>
          <div class="custom-table-height">
            <app-table
              [columns]="column"
              [data]="data"
              [config]="config"
              [contextmenu]="onRightClick"
              [cellValueChanged]="onItemChange"
            ></app-table>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div
    class="ap-flex ap-flex-row ap-justify-end ap-items-center ap-border-t ap-mx-4 ap-rounded ap-rounded-t-none ap-shadow-step-container-ds ap-bg-white ap-px-4 ap-py-2"
  >
    <ap-button
      actionButton
      (buttonClicked)="handleSaving()"
      btnSize="large"
      btnColor="primary"
      i18n
    >
      <div class="ap-flex ap-items-center loading-template">
        <svg-icon
          *ngIf="isSaving"
          src="assets/img/custom/loading.svg"
          class="loading-spin-animation white-loader"
          [applyClass]="true"
        >
        </svg-icon>
        {{ isSaving ? 'Submitting...' : 'Submit' }}
      </div>
    </ap-button>
  </div>

  <!-- Hidden div that triggers the menu -->
  <div
    #menuTrigger="matMenuTrigger"
    [style.left]="menuTopLeftPosition.x"
    [style.top]="menuTopLeftPosition.y"
    style="position: fixed"
    [matMenuTriggerFor]="contextMenu"
  ></div>

  <!-- Standard Material Menu -->
  <mat-menu #contextMenu="matMenu">
    <ng-container *ngIf="highlightedFieldObject">
      <button mat-menu-item (click)="copyToField()">
        <div class="ap-flex ap-gap-3 ap-items-center">
          <svg-icon
            [applyClass]="true"
            [src]="flowActionsUiInfo.copyToField.icon"
            [class]="flowActionsUiInfo.iconSizeTailWind"
          >
          </svg-icon>
          <span class="ap-text-body" i18n
            >{{ flowActionsUiInfo.copyToField.text
            }}{{ highlightedFieldObject.key }}</span
          >
        </div>
      </button>
    </ng-container>
    <button mat-menu-item (click)="copyToClipboard()">
      <div class="ap-flex ap-gap-3 ap-items-center">
        <svg-icon
          [applyClass]="true"
          [src]="flowActionsUiInfo.copyToClipboard.icon"
          [class]="flowActionsUiInfo.iconSizeTailWind"
        >
        </svg-icon>
        <span class="ap-text-body" i18n>{{
          flowActionsUiInfo.copyToClipboard.text
        }}</span>
      </div>
    </button>
  </mat-menu>
</div>
