import { Static, Type } from '@sinclair/typebox'

export const InviteVerificationResponse = Type.Object({
    email: Type.String(),
    registered: Type.Boolean(),
    userId: Type.Optional(Type.String()),
    projectId: Type.String(),
    platformId: Type.Union([Type.String(), Type.Null()]),
    invitedBy: Type.String(),
    invitedAt: Type.String(),
    inviteSecret: Type.String(),
    submittedAt: Type.String(),
})

export type InviteVerificationResponse = Static<
    typeof InviteVerificationResponse
>
