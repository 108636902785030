<div
  class="box-shadow ap-p-8 ap-flex-1 ap-overflow-auto ap-rounded ap-shadow-step-container-ds ap-bg-white ap-w-full ap-h-full ap-m-auto"
>
  <div class="ap-flex ap-flex-row ap-items-center ap-justify-end ap-mb-2">
    <ap-icon-button
      [iconFilename]="'close.svg'"
      (click)="onClose()"
    ></ap-icon-button>
  </div>

  <div class="ap-w-full pdf-height">
    <div
      class="ap-w-full ap-grid ap-grid-cols-1 sm:ap-grid-cols-2 ap-gap-3 ap-my-5"
    >
      <div class="ap-flex ap-flex-row ap-gap-1 ap-h-fit ap-items-center">
        <strong class="ap-w-fit">Spreadsheet: </strong>
        <p>{{ spreadsheetLogData.spreadsheet_name }}</p>
      </div>
      <div class="ap-flex ap-flex-row ap-gap-1 ap-h-fit ap-items-center">
        <strong class="ap-w-fit">Sheet: </strong>
        <p>{{ spreadsheetLogData.sheet_name }}</p>
      </div>
    </div>
    <div class="ap-w-full ap-grid ap-grid-cols-1 ap-gap-3 ap-my-5">
      <div class="ap-flex ap-flex-row ap-gap-1 ap-h-fit ap-items-center">
        <strong class="ap-w-1/4">Search Keywords</strong>
        <mat-form-field
          class="ap-w-full"
          appearance="outline"
          subscriptSizing="dynamic"
        >
          <mat-label>{{ spreadsheetLogData.searchValue }}</mat-label>
          <input
            matInput
            type="text"
            class=""
            [(ngModel)]="spreadsheetLogDataTemp.searchValue"
          />
        </mat-form-field>
      </div>
      <div class="ap-flex ap-flex-row ap-gap-1 ap-h-fit ap-items-center">
        <ng-container>
          <strong class="ap-w-1/4">Exact Match</strong>
          <mat-slide-toggle
            (change)="toggleClicked($event)"
            [checked]="spreadsheetLogData.matchCase"
            color="primary"
          >
          </mat-slide-toggle>
        </ng-container>
      </div>
    </div>
    <div
      class="ap-flex ap-flex-row ap-justify-end ap-items-center ap-gap-2 ap-my-8 ap-px-8"
    >
      <ap-button
        actionButton
        (buttonClicked)="handleSaving()"
        btnSize="large"
        btnColor="primary"
        [loading]="isLoading"
        i18n
      >
        <div class="ap-flex ap-items-center loading-template">Find</div>
      </ap-button>
    </div>

    <div class="ap-relative ap-max-w-full ap-h-[290px] ap-overflow-auto">
      <table
        *ngIf="dataSource.length && !errorMessage"
        mat-table
        [dataSource]="dataSource"
        class="mat-elevation-z8"
      >
        <ng-container matColumnDef="row">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="ap-top-0 ap-z-10 ap-sticky"
          >
            Row
          </th>
          <td mat-cell *matCellDef="let element">{{ element.row }}</td>
        </ng-container>

        <ng-container
          *ngFor="let column of displayColumns.slice(1)"
          [matColumnDef]="column"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            class="ap-top-0 ap-z-10 ap-sticky"
          >
            {{ column }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.values[column] }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayColumns; let i = index"
          [ngClass]="{ 'selected-row': selectedRowIndex === i }"
          (click)="onRowClick(i)"
        ></tr>
      </table>

      <div
        *ngIf="!dataSource.length && isLoading"
        class="ap-w-full ap-h-[50%] ap-absolute ap-flex ap-justify-center ap-items-center ap-top-7"
      >
        <ap-loading-icon
          height="50px"
          width="51px"
          [outlineLoader]="false"
          [whiteLoader]="false"
        >
        </ap-loading-icon>
      </div>

      <div *ngIf="errorMessage" class="ap-w-full ap-text-danger">
        <p>{{ errorMessage }}</p>
      </div>
    </div>

    <div
      *ngIf="dataSource.length"
      class="ap-flex ap-flex-row ap-justify-end ap-items-center ap-gap-2 ap-my-8 ap-px-8"
    >
      <ap-button
        actionButton
        (buttonClicked)="handleImporting()"
        btnSize="large"
        btnColor="primary"
        i18n
        [disabled]="!selectedRowIndex && selectedRowIndex !== 0"
      >
        <div class="ap-flex ap-items-center loading-template">Import</div>
      </ap-button>
    </div>
  </div>
</div>
