import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ap-user-avatar-icon',
  templateUrl: './user-avatar-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarIconComponent {
  @Input() imageUrl: string | undefined = undefined;

  constructor() {}
}
