import { createReducer, on } from '@ngrx/store';
import { UpbrainsUserState } from './upbrains-user-state.model';
import { removeUpbrainsUser, saveUpbrainsUser } from './upbrains-user.action';

export interface AuthState {
  upbrainsUser: UpbrainsUserState | null;
}

export const initialState: AuthState = {
  upbrainsUser: null,
};

export const upbrainsUserReducer = createReducer(
  initialState,
  on(saveUpbrainsUser, (state, { upbrainsUser }): AuthState => {
    return { ...state, upbrainsUser };
  }),
  on(
    removeUpbrainsUser,
    (state): AuthState => ({ ...state, upbrainsUser: null })
  )
);
