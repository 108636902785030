import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ColDef } from 'ag-grid-community';
import {
  TableColumns,
  TableConfig,
  TableData,
} from '../../utils/table-component.utils';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<T> implements OnInit, OnChanges {
  constructor() {}

  @Input() columns: TableColumns<T>[];
  @Input() data: TableData<T>[];
  @Input() config: TableConfig<T>;

  columnDefs: ColDef[] = [];

  rowData = [];

  getNestedValue(obj: any, path: string) {
    return path.split('.').reduce((acc, key) => acc[key], obj);
  }

  ngOnInit() {
    this.updateData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateData();
  }

  updateData() {
    let col: ColDef[] = [];

    col.push({
      width: 70,
      headerName: 'Index',
      valueGetter: 'node.rowIndex + 1',
    });

    this.columns.forEach((colItem) => {
      if (colItem.type == 'select') {
        col.push({
          headerName: colItem.title ?? (colItem?.name as string),
          field: colItem?.name as string,
          sortable: true,
          filter: true,
          editable: true,
          width: colItem?.width,
          cellEditor: 'agSelectCellEditor', // Use built-in dropdown editor
          cellEditorParams: (params: any) => {
            const value = this.getNestedValue(
              this.rowData[params.rowIndex],
              colItem.name as string
            );
            return {
              values: value, // Access the items array dynamically
            };
          },
        });
      } else if (colItem.type == 'component') {
        col.push({
          headerName: colItem.title ?? (colItem?.name as string),
          field: colItem?.name as string,
          sortable: true,
          cellRenderer: colItem?.component,
          cellRendererParams: colItem?.componentParmas,
          autoHeight: true,
          width: colItem?.width,
        });
      } else {
        col.push({
          headerName: colItem.title ?? (colItem?.name as string),
          field: colItem?.name as string,
          editable: colItem.isEdit ?? false,
          filter: true,
          width: colItem?.width,
          cellRenderer: colItem?.render ?? undefined,
          onCellValueChanged: (parmas: any) => {
            colItem?.onEdit?.(
              parmas.node.rowIndex,
              parmas.column.colId,
              parmas.newValue
            );
            this.config?.onEditCell?.(parmas.data);
          },
          onCellClicked: (parmas: any) => {
            this.config?.onRowClick?.(parmas.data);
            this.config?.onClickCell?.(parmas.data);
          },
        });
      }
    });

    this.columnDefs = col;

    this.rowData = this.data as any;
  }
}
