<div class="ap-my-8">
  <ag-grid-angular
    style="width: 100%; height: 400px"
    class="ag-theme-alpine"
    [animateRows]="false"
    [columnDefs]="columnDefs"
    [rowData]="rowData"
  >
  </ag-grid-angular>
</div>
