import {
  BuilderAutocompleteMentionsDropdownService,
  ConfirmationDialogComponent,
  ExtractorFileDialogComponent,
  ExtractorTextDialogComponent,
  NavigationService,
  SearchDialogComponent,
  TableHelper,
  environment,
  flowActionsUiInfo
} from '@upbrains/ui/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FlowRun,
  StaticFormsData,
  WebhookPauseMetadata,
} from '@upbrains/shared';
import { Observable, catchError, of, take } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { ColumnDescription } from './components/column-description.component';

export type TableColumn = {
  key: string;
  value: string;
  query?: string;
  order: number | undefined;
};

type TableRow = {
  key: string;
  value: string;
  query?: string;
  options?: {
    label: string;
    value: string;
  }[];
  autoCompleteValue?: string | null | undefined;
};

type ExtractorInfo = {
  display_name?: string;
  extractor_name?: string;
};

type OptionsType = { value?: string; matched_value?: string; score?: number };

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-custom-forms',
  templateUrl: './custom-form.component.html',
  styleUrls: ['./custom-form.component.scss'],
})
export class CustomFormComponent implements OnInit {
  @Input() selectedRun$: Observable<FlowRun | undefined> = of(undefined);
  @Input() title = '';
  @Input() staticFormsData: StaticFormsData | undefined = undefined;
  @Input() continueUrl = '';
  @Input() formType:
    | 'form_submission'
    | 'file_submission'
    | 'form-builder'
    | 'rfq_form'
    | 'document_review'
    | 'order_entry' = 'rfq_form';

  isOrder = false;
  logoSrc = 'assets/img/custom/logo/upbrains-logo-2x.png';
  columns: TableColumn[] = [];
  displayedColumns: string[] = ['item_num'];
  rows: any[] = [];
  extractorInfoObj: any = {};
  readonly flowActionsUiInfo = flowActionsUiInfo;
  selectedItem = '';
  items: any = {};
  copiedItems: any = {};
  showFields = false;
  runId: string | undefined = '';
  isDoc = false;
  fieldsForm: any = {};
  shortFieldsForm: any[] = [];
  longFieldsForm: any[] = [];
  ItemsForm: any = [];
  autoCompleteInputValue = '';
  // emailConfig: EmailConfig | undefined = undefined;
  // attachments: Attachment[] | undefined = [];
  fileUrl: string | undefined = undefined;
  text: string | undefined = undefined;
  isSaving = false;
  showTable = false;

  links: { approvalLink?: string; disapprovalLink?: string } | null = {};
  searchType: 'none' | 'spread_sheet' | 'custom_search_api' | undefined;

  constructor(
    private navigationService: NavigationService,
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private snackbar: MatSnackBar,
    public builderAutocompleteService: BuilderAutocompleteMentionsDropdownService,
    private http: HttpClient,
    private ref: ChangeDetectorRef
  ) {}

  //Todo: I suposed definition Types
  column: any[] = [];
  data: any[] = [];

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.runId = params['runId'];
    });

    if (this.staticFormsData?.formData) {
      this.searchType = this.staticFormsData?.formSearchType;
      this.items =
        this.staticFormsData.formData?.updated_extractor_payload?.extractor_result?.documents?.[0]?.items;

      this.showTable = Boolean(this.items.length);

      this.fieldsForm =
        this.staticFormsData.formData?.updated_extractor_payload?.extractor_result?.documents?.[0]?.fields;
      Object.entries(
        this.staticFormsData.formData?.updated_extractor_payload
          ?.extractor_result?.documents?.[0]?.fields
      ).forEach(([key, value]) => {
        if (
          this.staticFormsData?.formFieldsOrder?.fields &&
          Object.keys(this.staticFormsData?.formFieldsOrder?.fields).includes(
            key
          )
        ) {
          if (
            value.details?.['value'] &&
            value.details?.['value'].length > 30
          ) {
            this.longFieldsForm.push({
              ...value,
              key,
              order: this.staticFormsData?.formFieldsOrder?.fields[key],
            });
            this.longFieldsForm.sort((a, b) => {
              if (a.order === undefined && b.order === undefined) return 0;
              if (a.order === undefined) return 1;
              if (b.order === undefined) return -1;
              return a.order - b.order;
            });
          } else {
            this.shortFieldsForm.push({
              ...value,
              key,
              order: this.staticFormsData?.formFieldsOrder?.fields[key],
            });
            this.shortFieldsForm.sort((a, b) => {
              if (a.order === undefined && b.order === undefined) return 0;
              if (a.order === undefined || typeof a.order !== 'number')
                return 1;
              if (b.order === undefined || typeof b.order !== 'number')
                return -1;
              return a.order - b.order;
            });
          }
        }
      });

      this.ItemsForm =
        this.staticFormsData.formData?.updated_extractor_payload?.extractor_result?.documents?.[0]?.items;

      this.copiedItems = JSON.parse(JSON.stringify(this.items));
      this.columns = this.generateTableColumns(
        this.items,
        this.staticFormsData.formFieldsOrder?.columns || {}
      );

      this.rows = this.serializeTableRowData(this.items);
      this.columns.reverse().forEach((column) => {
        this.displayedColumns.push(column.key);
      });
      this.displayedColumns.push('remove');

      if (
        this.staticFormsData.formData?.updated_extractor_payload?.extractor_info
      ) {
        this.generateExtractorName(
          this.staticFormsData.formData?.updated_extractor_payload
            .extractor_info
        );
      }

      // TODO: uncomment the below lines
      this.showFields =
        this.formType.includes('document_review') ||
        this.formType.includes('order_entry');

      this.isDoc = this.formType.includes('document_review');

      this.text = this.staticFormsData.formText;
      this.fileUrl = this.staticFormsData.formFileUrl;

      //----------------------------------------------------------------
      this.TableUpdate();
      //----------------------------------------------------------------
    }
  }

  openSearchModal(rowIndex: number, query?: string) {
    if (this.staticFormsData?.formData) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;

      // Set full width and height
      dialogConfig.width = '100%';
      dialogConfig.height = '100%';
      dialogConfig.maxWidth = '80vw';
      dialogConfig.maxHeight = '80vh';
      dialogConfig.panelClass = 'full-screen-dialog';
      const displayedColumnsTemp = [...this.displayedColumns];
      displayedColumnsTemp.splice(
        displayedColumnsTemp.findIndex((item) => item === 'remove'),
        1
      );

      if (this.staticFormsData.spreadsheetLogData) {
        this.staticFormsData.spreadsheetLogData.searchValue = query;
      }
      const dataObj = {
        mainRowIndex: rowIndex,
        mainFormRows: this.rows,
        updateForm: this.updateForm,
        updateItemValue: this.updateItemValue,
        spreadsheetLogData: this.staticFormsData?.spreadsheetLogData,
        updateCandidate: this.handleKeyDown,
      };
      dialogConfig.data = dataObj;
      this.matDialog.open(SearchDialogComponent, dialogConfig);
    }
  }

  TableUpdate() {
    const columns = TableHelper.convertToTableColumns(
      this.copiedItems,
      ColumnDescription,
      this.staticFormsData?.formFieldsOrder?.columns,
      {
        searchEvent: (data: any, index: number) => {
          this.openSearchModal(index, data);
        },
        onEdit: (index: number, coloumnkey: string, value: string) => {
          this.updateItemValue(index, coloumnkey, value);
        },
      },
      (index, columnKey, value) => {
        this.updateItemValue(index, columnKey, value);
      }
    );
    const data = TableHelper.convertToTableData(this.copiedItems);

    columns.push({
      title: 'Operations',
      type: 'children',
      render: (params: any) => {
        const { element, setOnClick } = TableHelper.trashIcon(params);
        setOnClick((index) => {
          this.openConfirmationDialog(index);
        });
        return element;
      },
    });

    this.column = columns;
    this.data = data;

    this.ref.markForCheck();
  }

  ngOnDestroy(): void {
    this.snackbar.dismiss();
  }

  getFilteredOptions(
    options: { label: string; value: string }[],
    inputValue: string
  ): any {
    if (!inputValue) return options;
    return this.filter(inputValue, options);
  }

  private filter(
    value: string,
    options: { label: string; value: string }[]
  ): any[] {
    const filterValue = value?.toLowerCase();
    return options.filter((option) =>
      option.label?.toLowerCase().includes(filterValue)
    );
  }

  handleKeyDown = (
    value: string,
    rowOptions: any,
    rowIndex: number,
    columnKey: string
  ) => {
    this.addOptionIfNotExists(value, rowOptions, rowIndex);
    this.updateItemValue(rowIndex, columnKey, value);
  };

  addOptionIfNotExists(
    value: string,
    options: { label: string; value: string }[],
    rowIndex: number
  ): any {
    if (!value) return;
    const exists = options.some((option) =>
      option.label?.toLowerCase().includes(value?.toLowerCase())
    );
    if (!exists) {
      const newOption = { label: value, value: value };
      options.push(newOption);
    }

    // set the input value for the specific row
    this.rows[rowIndex].autoCompleteInputValue = value;

    return options;
  }

  openFile(): void {
    if (this.staticFormsData?.formData) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;

      // Set full width and height
      dialogConfig.width = '100%';
      dialogConfig.height = '100%';
      dialogConfig.maxWidth = '80vw';
      dialogConfig.maxHeight = '80vh';
      dialogConfig.panelClass = 'full-screen-dialog';
      dialogConfig.data = { fileUrl: this.fileUrl };
      this.matDialog.open(ExtractorFileDialogComponent, dialogConfig);
    }
  }
  openText(): void {
    if (this.staticFormsData?.formData) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;

      // Set full width and height
      dialogConfig.width = '100%';
      dialogConfig.height = '100%';
      dialogConfig.maxWidth = '80vw';
      dialogConfig.maxHeight = '80vh';
      dialogConfig.panelClass = 'full-screen-dialog';
      const dataObj = {
        formFields: this.fieldsForm,
        formItems: this.ItemsForm,
        formPages:
          this.staticFormsData.formData?.updated_extractor_payload
            ?.extractor_result?.pages,
      };
      dialogConfig.data = dataObj;
      this.matDialog.open(ExtractorTextDialogComponent, dialogConfig);
    }
  }

  handleSaving(): void {
    this.isSaving = true;
    const updatedFormData = this.staticFormsData?.formData;

    if (!updatedFormData) {
      this.isSaving = false;
      console.error("couldn't save");
    } else {
      updatedFormData['updated_extractor_payload']['extractor_result'][
        'documents'
      ][0]['items'] = this.copiedItems;

      this.selectedRun$.pipe(take(1)).subscribe((flowRun) => {
        if (!flowRun) {
          console.error('No flow run found.');
          return;
        }

        const requestId = (flowRun.pauseMetadata as WebhookPauseMetadata)
          .requestId;
        const postUrl = `${environment.apiUrl}/flow-runs/${this.runId}/requests/${requestId}`;

        this.http
          .post(postUrl, updatedFormData)
          .pipe(
            catchError((error) => {
              console.error('Error:', error);
              this.isSaving = false;
              return of(null);
            })
          )
          .subscribe(() => {
            setTimeout(() => {
              this.isSaving = false;
              this.router.navigate(['/forms']);
            }, 2000);
          });
      });
    }
  }

  redirectHome(newWindow: boolean) {
    this.navigationService.navigate('/forms', newWindow);
  }

  redirectToInbox(newWindow: boolean) {
    this.navigationService.navigate('/forms', newWindow);
  }

  hasQuery(key: string): boolean {
    return !!key?.length;
  }

  hasProperties(obj: any): boolean {
    return obj && Object.keys(obj).length > 0;
  }

  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  setInputForCustomization(index: number, value: string): void {
    this.selectedItem = `${index}-${value}`;
  }

  isPointerEventsNone(index: number, value: string): boolean {
    return `${index}-${value}` === this.selectedItem;
  }

  generateExtractorName(extractorInfo: ExtractorInfo) {
    this.extractorInfoObj = {
      ...(extractorInfo.display_name && { Name: extractorInfo.display_name }),
      ...(extractorInfo.extractor_name && {
        Extractor: extractorInfo.extractor_name,
      }),
    };
  }

  generateTableColumns(
    items: { [key: string]: any }[],
    columnsOrder: { [key: string]: number }
  ): TableColumn[] {
    const columns: TableColumn[] = [];
    const columnMap: { [key: string]: boolean } = {};
    items.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (
          key !== 'page_number' &&
          !columnMap[key] &&
          Object.keys(columnsOrder).includes(key)
        ) {
          const value = item[key]?.field_name || key;
          columns.push({
            key: key,
            value: value,
            order: columnsOrder?.[key] || 0,
          });
          columnMap[key] = true;
        }
      });
    });
    columns.sort((a, b) => {
      if (a.order === undefined && b.order === undefined) return 0;
      if (a.order === undefined) return -1;
      if (b.order === undefined) return 1;
      return b.order - a.order;
    });

    return columns;
  }

  serializeTableRowData(
    items: { [key: string]: any }[]
  ): { [key: string]: TableRow | string }[] {
    return items.map((item) => {
      const serializedItem: { [key: string]: TableRow | string } = {};
      Object.entries(item).forEach(([key, value]) => {
        if (
          value?.matches &&
          Array.isArray(value.matches) &&
          value.matches.length > 0
        ) {
          serializedItem[key] = {
            key: key,
            value:
              value.matches[0]?.result[0]?.matched_value ||
              value.matches[0]?.result[0]?.[key] ||
              '',
            options: [
              ...(value.matches[0]?.result[0]?.matched_value
                ? [
                    {
                      label: value.matches[0]?.result[0]?.matched_value
                        ? value.matches[0].result[0].matched_value
                        : value.matches[0]?.result[0]?.[key],
                      value: value.matches[0]?.result[0]?.matched_value
                        ? value.matches[0].result[0].matched_value
                        : value.matches[0]?.result[0]?.[key],
                    },
                  ]
                : []),
              // eslint-disable-next-line no-unsafe-optional-chaining
              ...value.matches[0]?.candidates?.map(
                (candidate: {
                  matched_value: string | null;
                  score: number;
                }) => ({
                  label:
                    candidate.matched_value ||
                    (key === 'matched_value' ? candidate.matched_value : ''),
                  value:
                    candidate.matched_value ||
                    (key === 'matched_value' ? candidate.matched_value : ''),
                })
              ),
            ],
            query: value.matches[0]?.query,
            autoCompleteValue: value.matches[0]?.result[0]?.matched_value
              ? value.matches[0].result[0].matched_value
              : value.matches[0].result[0]?.[key],
          };
        } else {
          serializedItem[key] = {
            key: key,
            value: value?.details?.value || value?.content || '',
          };
        }
      });
      return serializedItem;
    });
  }

  isObjectInArray(newObj: OptionsType, arr: OptionsType[]) {
    return arr.some(
      (obj) =>
        obj.value === newObj.value &&
        obj.matched_value === newObj.matched_value &&
        obj.score === newObj.score
    );
  }

  updateItemValue = (
    rowIndex: number,
    columnKey: string,
    value: any,
    label?: string
  ): void => {
    const item = this.copiedItems[rowIndex];
    if (item[columnKey]?.matches && Array.isArray(item[columnKey].matches)) {
      const result = item[columnKey].matches[0].result[0];
      item[columnKey].matches[0].result[0] = {
        ...item[columnKey].matches[0].result[0],
        value:
          value ||
          item[columnKey].matches[0]?.result[0]?.matched_value ||
          item[columnKey].matches[0]?.result[0]?.[columnKey] ||
          '',
        matched_value:
          value ||
          item[columnKey].matches[0]?.result[0]?.matched_value ||
          item[columnKey].matches[0]?.result[0]?.[columnKey] ||
          '',
      };
      if (!label) {
        const newOption = {
          [columnKey]: value,
          matched_value: value,
          value,
          score: 100,
        };
        if (
          !item[columnKey].matches[0].candidates.some(
            (candidate: any) =>
              candidate.matched_value == newOption.matched_value ||
              candidate[columnKey] === newOption[columnKey]
          )
        ) {
          item[columnKey].matches[0].candidates.push(newOption);
        }
        if (
          result &&
          !item[columnKey].matches[0].candidates.some(
            (candidate: any) => candidate.matched_value === result.matched_value
          )
        ) {
          item[columnKey].matches[0].candidates.push(result);
        }
      }
    } else {
      if (item[columnKey]?.content) {
        item[columnKey].content = value;
      }
      if (item[columnKey]?.details?.value) {
        item[columnKey].details.value = value;
      }
    }

    this.TableUpdate();
    // }, 0);
  };

  handleSelectOption(
    rowIndex: number,
    columnKey: string,
    value: string,
    label: string
  ) {
    this.updateItemValue(rowIndex, columnKey, value, label);
  }

  addNewRow(): void {
    const newItem = {} as { [key: string]: any };

    // Populate the new item with empty/default values based on the first existing item
    const sampleItem = (this.copiedItems[0] as { [key: string]: any }) || {};
    for (const key of Object.keys(sampleItem)) {
      if (Array.isArray(sampleItem[key])) {
        newItem[key] = [
          {
            query: '',
            result: [
              {
                score: 0,
                value: '',
                matched_value: '',
              },
            ],
            status: 200,
            metadata: {
              action: 'Find Partial Match',
              category: 'Find Partial Match',
            },
            uncertain: false,
            candidates: [],
          },
        ];
      } else if (typeof sampleItem[key] === 'object') {
        newItem[key] = {
          content: '',
          details: {
            value: 0,
          },
          field_name: key,
          type: typeof sampleItem[key]?.details?.value,
        };
      } else {
        newItem[key] = '';
      }
    }

    this.copiedItems.push(newItem);
    this.rows = this.serializeTableRowData(this.copiedItems);

    this.TableUpdate();
  }

  isLongText(value: string): boolean {
    return value.length > 30;
  }

  openConfirmationDialog(index: number): void {
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        title: 'Delete Row',
        description: 'Are you sure you want to delete this row?',
        onClose: () => {
          console.log('Dialog was closed');
        },
        onConfirm: () => {
          this.removeRow(index);
        },
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log('Confirmed');
      } else {
        console.log('Cancelled');
      }
    });
  }

  removeRow(index: number): void {
    this.copiedItems.splice(index, 1);
    this.rows = this.serializeTableRowData(this.copiedItems);
    this.TableUpdate();
    this.ref.markForCheck();
  }

  updateForm = (form: any[]) => {
    this.rows = form;
    this.ref.detectChanges();
  };
}
