<div
  class="ap-h-screen ap-w-full ap-flex ap-items-center ap-justify-center ap-bg-center ap-bg-cover ap-flex-col"
>
  <div class="ap-h-[52px] ap-mb-4 ap-bg-white">
    <img
      alt="UpBrains Website"
      [src]="'assets/img/custom/logo/upbrains-logo-2x.png'"
      class="ap-h-full"
    />
  </div>

  <mat-card
    class="md:ap-min-w-[480px] md:ap-max-w-initial ap-min-w-[350px] ap-max-w-[350px] auth-card"
  >
    <router-outlet></router-outlet>
  </mat-card>
</div>
